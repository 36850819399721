import React, { type ReactNode, Suspense, useMemo } from 'react';

import { graphql, useLazyLoadQuery } from 'react-relay';

import UFOSegment from '@atlaskit/react-ufo/segment';

import {
	EditionAwarenessContext,
	type EditionAwarenessContextType,
} from '../services/edition-awareness-context';
import { getChargeElementKey } from '../services/get-charge-element-key';
import { getTrialDaysLeft } from '../services/get-trial-days-left';
import { getTrialOfferingName } from '../services/get-trial-offering-name';
import { getHamsKey } from '../services/product-to-hams-key';
import { useConfig } from '../services/use-config';
import { useSpotlight } from '../services/use-spotlight';
import type { Edition, Product, SiteType, UserRole } from '../types';

import type { editionAwarenessQuery } from './__generated__/editionAwarenessQuery.graphql';
import { AudienceAndTiming } from './audience-and-timing';
import { ErrorBoundary } from './error-boundary';
import { Spotlight } from './spotlight';

export type EditionAwarenessProps = {
	cloudId: string;
	product: Product;
	edition: Edition;
	freeButton: ReactNode;
	trialButton: ReactNode;
	siteType?: SiteType;
	userRoles?: UserRole[];
	hasDismissedButton?: boolean;
	onDismissButton?: () => void;
};

/**
 * Edition awareness is used to communicate to customers about the current edition they have and if they should
 * - Add payment details
 * - Upgrade their edition
 *
 * This component deals with creating the context which is then used throughout the package to:
 * - Track analytics
 * - Know which product/edition/cloud id is being used
 * - Know when to show/hide the button
 */
function EditionAwarenessImpl({ freeButton, trialButton, ...props }: EditionAwarenessProps) {
	const {
		edition,
		product,
		cloudId,
		userRoles: userRolesFromProduct,
		hasDismissedButton,
		onDismissButton,
		siteType,
	} = props;

	const config = useConfig(product, edition);
	const { isSpotlightActive, handleSetSpotlight } = useSpotlight();

	const trialOfferingName = getTrialOfferingName(edition, config);
	const shouldCheckForPaidStandard = Boolean(
		trialOfferingName === 'premium' &&
			userRolesFromProduct?.includes('SITE_ADMIN') &&
			config.isPaidStandard,
	);

	const data = useLazyLoadQuery<editionAwarenessQuery>(
		graphql`
			query editionAwarenessQuery(
				$cloudId: ID!
				$productKey: String!
				$trialOfferingName: String!
				$shouldCheckForChangeOffering: Boolean!
				$shouldCheckForTrial: Boolean!
				$shouldSkip: Boolean!
				$shouldCheckForChargeQuantities: Boolean!
				$entitlementFilter: CommerceEntitlementFilter
				$chargeElement: String!
				$shouldCheckForPaidOffering: Boolean!
				$shouldCheckBillingAdmin: Boolean!
			) {
				...audienceAndTiming_AtlassianEditionAwareness

				tenantContexts(cloudIds: [$cloudId]) @skip(if: $shouldSkip) {
					entitlementInfo(hamsProductKey: $productKey) {
						paidEntitlement: entitlement(where: { inTrialOrPreDunning: false })
							@include(if: $shouldCheckForPaidOffering) {
							experienceCapabilities {
								changeOfferingV2(offeringName: $trialOfferingName) {
									isAvailableToUser
									experienceUrl
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										invoiceable
									}
								}
							}
						}

						entitlement(where: $entitlementFilter) {
							experienceCapabilities @include(if: $shouldCheckForChangeOffering) {
								changeOfferingV2(offeringName: $trialOfferingName) {
									isAvailableToUser
									experienceUrl
								}
							}

							preDunning @include(if: $shouldCheckForTrial) {
								status
							}

							subscription @include(if: $shouldCheckForTrial) {
								trial {
									endTimestamp
								}
								accountDetails {
									invoiceGroup {
										invoiceable
										experienceCapabilities {
											configurePaymentV2 {
												isAvailableToUser
											}
										}
									}
								}
							}

							transactionAccount @include(if: $shouldCheckBillingAdmin) {
								isCurrentUserBillingAdmin
							}
						}
					}
				}
			}
		`,
		{
			shouldSkip: config?.shouldSkip === true,
			trialOfferingName,
			shouldCheckForChangeOffering: edition === 'free',
			shouldCheckForTrial: edition === 'premium' || edition === 'standard',
			cloudId,
			productKey: getHamsKey(product),
			shouldCheckForChargeQuantities:
				config.warnOnUserLimit === true ||
				config.isStandardTrialsUserAndStorageLimitBreakersAAEnabled === true ||
				// Confluence doesn't use `warnOnUserLimit` and has its own variety
				(product === 'confluence' && edition === 'free'),
			// The entitlement filter ensures that we apply the `inTrialOrPreDunning` only when an entitlement is
			// on a non free edition. The filter is based on the trial end timestamp inside of TCS, this means that
			// if a customer downgrades their product to free while on a trial, then they will only show up
			// if inTrialOrPreDunning is set to true as the filter assumes they are still on trial.
			entitlementFilter: edition !== 'free' ? { inTrialOrPreDunning: true } : null,
			chargeElement: getChargeElementKey(product),
			shouldCheckForPaidOffering: shouldCheckForPaidStandard,
			shouldCheckBillingAdmin: config.shouldCheckBillingAdmin === true,
		},
	);

	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;

	const isInvoiceable = entitlement?.subscription?.accountDetails?.invoiceGroup?.invoiceable;

	const canAddPaymentDetails =
		entitlement?.subscription?.accountDetails?.invoiceGroup?.experienceCapabilities
			?.configurePaymentV2?.isAvailableToUser === true;

	const trialDaysLeft = getTrialDaysLeft(entitlement?.subscription?.trial?.endTimestamp);
	const isInTrial = Boolean(trialDaysLeft && trialDaysLeft > 0);

	const isInPredunning = entitlement?.preDunning?.status === 'IN_PRE_DUNNING' && !isInvoiceable;

	const isChangeOfferingAvailable =
		entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser;
	const changeOfferingExperienceUrl =
		entitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl;

	const canUpgrade = Boolean(isChangeOfferingAvailable && changeOfferingExperienceUrl);

	const paidEntitlement = data?.tenantContexts?.[0]?.entitlementInfo?.paidEntitlement;
	const paidEntitlementContext = useMemo(
		() => ({
			isInvoiceable: paidEntitlement?.subscription?.accountDetails?.invoiceGroup?.invoiceable,
			canUpgrade: Boolean(
				paidEntitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser &&
					paidEntitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl,
			),
		}),
		[paidEntitlement],
	);

	const userRoles: UserRole[] = useMemo(() => {
		const roles: UserRole[] = [];
		if (userRolesFromProduct?.includes('SITE_ADMIN')) {
			roles.push('SITE_ADMIN');
		}
		if (
			data?.tenantContexts?.[0]?.entitlementInfo?.entitlement?.transactionAccount
				?.isCurrentUserBillingAdmin
		) {
			roles.push('BILLING_ADMIN');
		}
		return roles;
	}, [data?.tenantContexts, userRolesFromProduct]);

	const context: EditionAwarenessContextType = useMemo(() => {
		return {
			cloudId,
			product,
			edition,
			isInvoiceable,
			canAddPaymentDetails,
			trialDaysLeft,
			isInTrial,
			isInPredunning,
			canUpgrade,
			config,
			userRoles,
			hasDismissedButton,
			onDismissButton,
			siteType,
			isSpotlightActive,
			handleSetSpotlight,
			paidEntitlement: paidEntitlementContext,
		};
	}, [
		cloudId,
		product,
		edition,
		isInvoiceable,
		canAddPaymentDetails,
		trialDaysLeft,
		isInTrial,
		isInPredunning,
		canUpgrade,
		config,
		userRoles,
		hasDismissedButton,
		onDismissButton,
		siteType,
		isSpotlightActive,
		handleSetSpotlight,
		paidEntitlementContext,
	]);

	return (
		<EditionAwarenessContext.Provider value={context}>
			<ErrorBoundary product={product} edition={edition} id="audience-and-timing">
				<AudienceAndTiming freeButton={freeButton} trialButton={trialButton} entitlement={data} />
				<Spotlight />
			</ErrorBoundary>
		</EditionAwarenessContext.Provider>
	);
}

export function EditionAwareness(props: EditionAwarenessProps) {
	const { product, edition } = props;

	return (
		<UFOSegment name="edition-awareness-platform">
			{/* This needs to be something that is not null, if it is null then the error will
				bubble up to the parent as `ErrorBoundary` will throw an exception if it is passed `null` */}
			<ErrorBoundary product={product} edition={edition} id="edition-awareness">
				<Suspense fallback={null}>
					<EditionAwarenessImpl {...props} />
				</Suspense>
			</ErrorBoundary>
		</UFOSegment>
	);
}
