import { useIntl } from 'react-intl-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { ExperimentCohortControlVariation, Product } from '../types';

import { useEditionAwareness } from './edition-awareness-context';
import { isEligibleForEduDiscount } from './is-eligible-for-edu-discount';
import { isEligibleForStandardToPremium } from './is-eligible-for-standard-to-premium';

const PRODUCT_TO_TRANSLATION_KEY: Record<Product, string> = {
	jira: 'jira',
	'jira-service-management': 'jsm',
	'jira-product-discovery': 'jpd',
	confluence: 'confluence',
};

/**
 * When translating with the product as the key you need to use plain text without dashes for the icuj4 format
 * to support select statements
 */
function getProductForTranslation(product: Product) {
	return PRODUCT_TO_TRANSLATION_KEY[product];
}

function getProductLimits(product: Product) {
	if (product === 'jira-product-discovery') {
		return {
			// TODO: `licensedUserLimit` should be fetched from the server
			licensedUserLimit: 3,
			warningUserLimit: 2,
		};
	}

	if (product === 'jira') {
		return {
			// TODO: `licensedUserLimit` should be fetched from the server
			licensedUserLimit: 10,
			warningUserLimit: 7,
		};
	}

	if (product === 'confluence') {
		return {
			// TODO: `licensedUserLimit` should be fetched from the server
			licensedUserLimit: 10,
			warningUserLimit: 3,
		};
	}

	if (product === 'jira-service-management') {
		return {
			// TODO: `licensedUserLimit` should be fetched from the server
			licensedUserLimit: 3,
			warningUserLimit: 2,
		};
	}

	return null;
}

export type IconType = 'gem' | 'warning' | 'credit-card';
export type Appearance = 'default' | 'warning' | 'danger';

export type ButtonContent = {
	text: string;
	appearance: 'default' | 'warning' | 'danger';
	icon: IconType;
	isDismissible?: boolean;
	isEducationDiscount?: boolean;
};

export function useButtonContent({
	licensedUsers,
	storageUsageInGB,
}: {
	licensedUsers?: number | null;
	storageUsageInGB?: number;
}): ButtonContent | null {
	const { formatMessage } = useIntl();

	const context = useEditionAwareness();
	const {
		edition,
		config,
		product,
		trialDaysLeft,
		isInTrial,
		isInPredunning,
		isInvoiceable,
		canAddPaymentDetails,
	} = context;

	const limits = getProductLimits(product);

	// Education offer has a higher priority than all the other messages
	if (
		isEligibleForEduDiscount(context) &&
		FeatureGates.getExperimentValue<ExperimentCohortControlVariation>(
			'offer_edu_org_discount',
			'cohort',
			'not-enrolled',
		) === 'variation'
	) {
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.education-offer',
				defaultMessage: 'Education discount',
				description:
					'Informs the user that they may be are eligible for an education offer which is a discount on all products',
			}),
			appearance: 'default',
			icon: 'gem',
			isEducationDiscount: true,
		};
	}

	if (config.warnOnUserLimit && licensedUsers && limits) {
		if (licensedUsers >= limits.licensedUserLimit) {
			return {
				text: formatMessage(
					{
						id: '@atlassian/edition-awareness.button.jpd.no-creators-left',
						defaultMessage:
							'No {product, select, jpd {creators} jsm {agents} jira {users} confluence {users} other {users}} left',
						description:
							'Informs the user that there are no licensed users left and they may need to upgrade their product',
					},
					{ product: getProductForTranslation(product) },
				),
				appearance: product !== 'confluence' ? 'danger' : 'warning',
				icon: 'warning',
			};
		}

		if (licensedUsers >= limits.warningUserLimit) {
			return {
				text: formatMessage(
					{
						id: '@atlassian/edition-awareness.button.jpd.limited-creators-left',
						defaultMessage:
							'Limited {product, select, jpd {creators} jsm {agents} jira {users} confluence {users} other {users}} left',
						description:
							'Informs the user that there are limited licensed users left before they need to upgrade their product',
					},
					{ product: getProductForTranslation(product) },
				),
				appearance: 'warning',
				icon: 'warning',
			};
		}
	}

	if (product === 'confluence' && edition === 'free' && licensedUsers && licensedUsers >= 3) {
		// Confluence has its own variety when compared to the `userLimits` feature
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.upgrade',
				defaultMessage: 'Upgrade',
				description: 'Informs the user that they can upgrade their product',
			}),
			appearance: 'warning',
			icon: 'warning',
		};
	}

	if (edition === 'free') {
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.upgrade',
				defaultMessage: 'Upgrade',
				description: 'Informs the user that they can upgrade their product',
			}),
			appearance: 'default',
			icon: 'gem',
		};
	}

	if (
		edition === 'standard' &&
		(isInTrial || isInPredunning) &&
		isInvoiceable === false &&
		canAddPaymentDetails
	) {
		const addPaymentDetailsText = formatMessage({
			id: '@atlassian/edition-awareness.button.addPaymentDetails',
			defaultMessage: 'Add payment details',
			description: 'Informs the user how many days they have left in trial',
		});
		const daysLeftText = formatMessage(
			{
				id: '@atlassian/edition-awareness.button.daysLeft',
				defaultMessage: '{trialDaysLeft} days left',
				description: 'Informs the user how many days they have left in trial',
			},
			{ trialDaysLeft },
		);

		return {
			text: isInPredunning ? addPaymentDetailsText : daysLeftText,
			appearance: 'warning',
			icon: 'warning',
		};
	}

	if (isEligibleForStandardToPremium(context)) {
		return {
			text: formatMessage({
				id: '@atlassian/edition-awareness.button.upgrade',
				defaultMessage: 'Upgrade',
				description: 'Informs the user that they can upgrade their product',
			}),
			appearance: 'default',
			icon: 'gem',
			isDismissible: true,
		};
	}

	return null;
}
